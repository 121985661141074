import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import CategoryGroupingTable from './components/CategoryGroupingTable/CategoryGroupingTable';
import IsolationTable from './components/IsolationTable/IsolationTable';
import CampProfileTable from './components/CampProfileTable/CampProfileTable';
import BusinessPartnerGroupingTable from './components/BusinessPartnerTable/BusinessPartnerTable';
import Totals from './components/Totals/Totals';
import { StoreContext } from '../../../stores/RootStore';

export default observer(function HomePage() {
  const { campStore, homePage, campProfileStore } = useContext(StoreContext);

  useEffect(() => {
    async function loadCamps() {
      await campStore.getCampsAsync();
      if (campStore.camps.length > 0) {
        homePage.setCamp(campStore.camps[0]);
      }
    }

    loadCamps();
  }, [campStore, homePage]);

  useEffect(() => {
    if (homePage.camp) {
      campProfileStore.getCampProfiles();
    }
  }, [campProfileStore, homePage.camp]);

  return (
    <>
      <Totals />
      <CategoryGroupingTable />
      <IsolationTable />
      <BusinessPartnerGroupingTable />
      <CampProfileTable />
    </>
  );
});
