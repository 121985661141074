export enum CampProfileTypes {
  ServicePersonnel = 1,
  NonBadged = 2,
  InIsolation = 3,
  ShelterInAccommodation = 4,
  TotalExcludeIsolationAndSiA = 5,
  SingleRoom = 6,
  DoubleRoom = 7,
  TripleRoom = 8,
  QuadRoom = 9,
  BathRoom = 10,
  IsolationRoom = 11,
  SanitaryEpidemiologicalStationRoom = 12,
}
