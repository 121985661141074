import { ChangeEvent, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import BlockIcon from '@material-ui/icons/Block';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StoreContext } from '../../../../../stores/RootStore';
import ActionButtons from '../ActionButtons/ActionButtons';
import { EditMode } from '../../../../../stores/EditMode';
import ISelectItemDto from '../../../../../../domain/domainsDTOs/Common/ISelectItemDto';
import NoRowsOverlay from '../../../../table/NoRowsOverlay';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validationSchema } from './ValidationSchema';

interface IFormValues {
  rows: {
    company: ISelectItemDto | null;
    count: number;
  }[];
}

export default observer(function BusinessPartnerTable() {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { homePage } = useContext(StoreContext);

  useEffect(() => {
    homePage.getBusinessPartners();
    homePage.getCompanies();
  }, [homePage]);

  const { errors, values, isValid, isSubmitting, setFieldValue, submitForm, resetForm } = useFormik<IFormValues>({
    initialValues: {
      rows: homePage.businessPartners.map((headcount) => ({
        company: { id: headcount.companyId, name: headcount.companyName },
        count: headcount.value,
      })),
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      try {
        await homePage.addHeadcountOfBusinessPartnersAsync(
          formValues.rows as { company: ISelectItemDto; count: number }[]
        );
        resetForm();
        homePage.setMode(EditMode.None);
        homePage.getBusinessPartners();
      } catch (e) {
        enqueueSnackbar('Something went wrong!', {
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          variant: 'error',
        });
      }
    },
  });

  function filterOptions(options: ISelectItemDto[]) {
    return options.filter((option) => {
      return !values.rows.find((row) => row.company?.id === option.id);
    });
  }

  function handleChange(index: number) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setFieldValue(`rows[${index}].count`, 0);
      }
      if (Number.isNaN(parseInt(e.target.value))) {
        return;
      }
      setFieldValue(`rows[${index}].count`, parseInt(e.target.value));
    };
  }

  return (
    <Box mt={3} style={{ position: 'relative' }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={3}>
          <ActionButtons
            currentEditMode={homePage.editMode}
            editMode={EditMode.HeadcountBp}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onCancel={() => {
              resetForm();
              homePage.setMode(EditMode.None);
            }}
            onSave={submitForm}
            onEdit={() => homePage.setMode(EditMode.HeadcountBp)}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="h6">
            <FormattedMessage id="Home.BusinessPartnerTable.Name" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>
            <FormattedMessage id="Misc.Total" />: {homePage.counters.businessPartnerTotal ?? '-'}
          </Typography>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{formatMessage({ id: 'Home.BusinessPartnerTable.BusinessPartnerName' })}</TableCell>
              <TableCell width="20%">{formatMessage({ id: 'Home.BusinessPartnerTable.PeopleCount' })}</TableCell>
              <TableCell width="20%">{formatMessage({ id: 'Home.BusinessPartnerTable.LastModifiedDate' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {homePage.editMode !== EditMode.HeadcountBp && homePage.businessPartners.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <NoRowsOverlay />
                </TableCell>
              </TableRow>
            ) : null}
            {homePage.editMode !== EditMode.HeadcountBp &&
              homePage.businessPartners.map((item) => (
                <TableRow key={item.companyId}>
                  <TableCell>{item.companyName}</TableCell>
                  <TableCell>{item.value}</TableCell>
                  <TableCell colSpan={2}>{format(new Date(item.date), 'dd-MM-yyyy HH:mm:ss')}</TableCell>
                </TableRow>
              ))}
            {homePage.editMode === EditMode.HeadcountBp &&
              values.rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Autocomplete
                      options={homePage.companies}
                      getOptionLabel={(option) => option.name ?? ''}
                      filterOptions={filterOptions}
                      getOptionSelected={(option, value) => option.id === value.id}
                      style={{ width: 300 }}
                      disabled={isSubmitting}
                      value={row.company}
                      onChange={(e, selectedItem) => {
                        setFieldValue(`rows.${i}.company`, selectedItem);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" error={!!errors.rows?.[i]} />}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      disabled={isSubmitting}
                      value={row.count}
                      error={!!errors.rows?.[i]}
                      onChange={handleChange(i)}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    <IconButton
                      disabled={isSubmitting}
                      onClick={() =>
                        setFieldValue(
                          'rows',
                          values.rows.filter((r, index) => index !== i)
                        )
                      }
                    >
                      <BlockIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {filterOptions(homePage.companies).length && homePage.editMode === EditMode.HeadcountBp ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <IconButton
                    size="small"
                    disabled={
                      isSubmitting || (!isValid && !!values.rows.length) || !filterOptions(homePage.companies).length
                    }
                    onClick={() => setFieldValue('rows', [...values.rows, { company: null, count: 0 }])}
                  >
                    <AddCircleRoundedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      {homePage.businessPartnersLoading ? (
        <Grid container justify="center" alignItems="center" style={{ position: 'absolute', top: 0, bottom: 0 }}>
          <CircularProgress />
        </Grid>
      ) : null}
    </Box>
  );
});
