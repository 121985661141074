import { makeAutoObservable } from 'mobx';
import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICampAreaRepository from '../../../domain/repository/Common/ICampAreaRepository';

export default class CampAreaStore {
  campAreas: ISelectItemDto[] = [];
  constructor(private campAreaRepository: ICampAreaRepository) {
    makeAutoObservable(this);
  }

  async getCampAreasAsync() {
    this.campAreas = await this.campAreaRepository.getCampAreas();
  }
}
