import { Switch, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage';
import ManageCampAdmins from '../pages/ManageCampAdminsPage/ManageCampAdminsPage';
import Auth from '../auth/Auth';
import NotFoundPage from '../pages/ErrorPage/NotFoundPage';
import ForbiddenPage from '../pages/ErrorPage/ForbiddenPage';
import InternalServerErrorPage from '../pages/ErrorPage/InternalServerErrorPage';
import MainLayout from '../../layouts/main/Layout';
import ErrorLayout from '../../layouts/error/Layout';
import PrivateRoute from '../auth/PrivateRoute';
import { Roles } from '../../../domain/enums/Roles';

export default function Routes() {
  return (
    <Switch>
      <Route path={['/', '/manage-camp-admins']} exact>
        <Auth>
          <MainLayout>
            <Switch>
              <PrivateRoute roles={[Roles.SPoCCampAdmin]} path="/manage-camp-admins" component={ManageCampAdmins} />
              <Route path="/" exact component={HomePage} />
            </Switch>
          </MainLayout>
        </Auth>
      </Route>
      <Route path="*">
        <ErrorLayout>
          <Switch>
            <Route path="/error/internal-server-error" component={InternalServerErrorPage} />
            <Route path="/error/forbidden" component={ForbiddenPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </ErrorLayout>
      </Route>
    </Switch>
  );
}
