import { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { object, number, string } from 'yup';
import { GridCellParams } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StoreContext } from '../../../../../stores/RootStore';
import PhoneNumberInput from '../../../../form/PhoneNumberInput';

interface IFormValues {
  campId: number;
  isActive: boolean;
  phoneNumber: string;
}

export default function EditDialog(props: GridCellParams) {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { campStore, manageCampAdminPage } = useContext(StoreContext);

  const formik = useFormik<IFormValues>({
    initialValues: {
      campId: props.getValue('campId') as number,
      isActive: (props.getValue('status') as boolean) ?? false,
      phoneNumber: (props.getValue('phoneNumber') as string) ?? '',
    },
    enableReinitialize: true,
    onSubmit: (formValues, helpers) => {
      manageCampAdminPage.edit({
        ...formValues,
        userCampId: props.getValue('id') as number,
        campId: formik.values.campId as number,
        userId: props.getValue('userId') as number,
      });
      helpers.resetForm();
      setIsOpen(false);
    },
    validationSchema: object().shape({
      campId: number().required(),
      phoneNumber: string()
        .required()
        .matches(/^[+]?[0-9()\-\s]+$/),
    }),
  });

  function handleCancel() {
    setIsOpen(false);
    formik.resetForm();
  }

  function handleModalOpen() {
    setIsOpen(true);
  }

  return (
    <>
      <IconButton onClick={handleModalOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={isOpen} onClose={handleCancel}>
        <DialogTitle>{props.getValue('email')}</DialogTitle>
        <DialogContent>
          <Box mb={1}>
            <Autocomplete
              options={campStore.camps}
              getOptionLabel={(option) => option.name}
              value={campStore.camps.find((c) => c.id === formik.values.campId)}
              onChange={(e, v) => formik.setFieldValue('campId', v?.id)}
              closeIcon={null}
              style={{ width: 300 }}
              renderInput={(params: any) => (
                <TextField
                  placeholder="Select Camp"
                  error={!!formik.errors.campId}
                  {...params}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box mb={1}>
            <TextField
              placeholder="Phone number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange('phoneNumber')}
              size="small"
              onKeyDown={(e) => e.stopPropagation()}
              error={!!formik.errors.phoneNumber}
              variant="outlined"
              style={{ width: 300 }}
              InputProps={{
                inputComponent: PhoneNumberInput as any,
              }}
            />
          </Box>
          <Box mb={1}>
            <FormControlLabel
              control={
                <Checkbox checked={formik.values.isActive} onChange={formik.handleChange('isActive')} color="primary" />
              }
              label={formatMessage({ id: 'ManageCampAdmins.Form.IsActive' })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={formik.submitForm} disabled={!formik.isValid || !formik.dirty} color="primary">
            {formatMessage({ id: 'Misc.SaveChanges' })}
          </Button>
          <Button onClick={handleCancel} color="secondary">
            {formatMessage({ id: 'Misc.CancelChanges' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
