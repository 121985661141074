import ICampProfileRepository from '../../../domain/repository/SinglePointOfContact/ICampProfileRepository';
import IHttpService from '../../../domain/services/IHttpService';
import ICreateOrUpdateCampProfilesDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICreateOrUpdateCampProfilesDto';
import IGetCampProfilesDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/IGetCampProfilesDto';
import ICampProfileDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICampProfileDto';

export default class CampProfileRepository implements ICampProfileRepository {
  constructor(private _httpService: IHttpService) {}

  getCampProfiles(data: IGetCampProfilesDto): Promise<ICampProfileDto[]> {
    return this._httpService.post('/api/SPOC/CampProfile/GetCampProfiles', data);
  }

  createOrUpdate(data: ICreateOrUpdateCampProfilesDto): Promise<number> {
    return this._httpService.post('/api/SPOC/CampProfile/CreateOrUpdate', data);
  }
}
