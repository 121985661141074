import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect, useContext } from 'react';
import { StoreContext } from '../../stores/RootStore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';

interface Props {}

export default observer(function Auth(props: PropsWithChildren<Props>) {
  const { userStore } = useContext(StoreContext);
  useEffect(() => {
    userStore.getUser();
  }, [userStore]);

  if (userStore.loading) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return userStore.currentUser ? <>{props.children}</> : null;
});
