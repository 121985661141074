import { flow, makeAutoObservable } from 'mobx';
import IBusinessPartnerRepository from '../../../domain/repository/SinglePointOfContact/IBusinessPartnerRepository';
import ICampProfileRepository from '../../../domain/repository/SinglePointOfContact/ICampProfileRepository';
import { EditMode } from '../EditMode';
import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICompanyRepository from '../../../domain/repository/Common/ICompanyRepository';
import { RootStore } from '../RootStore';
import ICreateOrUpdateBusinessPartnerDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/ICreateOrUpdateBusinessPartnerDto';
import IBusinessPartnerDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/IBusinessPartnerDto';
import ValidationError from '../../../domain/errors/ValidationError';
import CampProfileValidation from '../domain/CampProfileValidation';
import CampProfileCounters from '../domain/CampProfileCounters';
import ICampProfileDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICampProfileDto';

export default class HomePageStore {
  businessPartners: Array<IBusinessPartnerDto & { companyName: string }> = [];
  businessPartnersLoading = false;
  companies: ISelectItemDto[] = [];
  camp: ISelectItemDto | null = null;
  editMode: EditMode = EditMode.None;
  validation = new CampProfileValidation();
  counters: CampProfileCounters;
  constructor(
    private rootStore: RootStore,
    private businessPartnerRepository: IBusinessPartnerRepository,
    private campProfileRepository: ICampProfileRepository,
    private companyRepository: ICompanyRepository
  ) {
    makeAutoObservable(this, {
      getBusinessPartners: flow,
      submit: flow,
    });
    this.counters = new CampProfileCounters(rootStore.campProfileStore.campProfiles, this.businessPartners);
    this.setMode = this.setMode.bind(this);
  }

  setMode(mode: EditMode) {
    this.editMode = mode;

    if (mode === EditMode.None) {
      this.validation.reset();
    }
  }

  setCamp(camp: ISelectItemDto | null) {
    this.camp = camp;
    this.getBusinessPartners();
  }

  *submit(data: ICampProfileDto[]) {
    if (!this.camp) {
      return;
    }

    const items = new Map(this.rootStore.campProfileStore.campProfiles);

    for (var item of data) {
      items.set(item.campProfileTypeId, item);
    }

    const isValid = this.validation.check(items, this.businessPartners);

    if (!isValid) {
      throw new ValidationError();
    }

    yield this.rootStore.campProfileStore.createOrUpdate(
      Array.from(items.values()).map(({ campProfileTypeId, value, comments = undefined }) => ({
        campId: this.camp!.id,
        campProfileTypeId,
        value,
        comments,
      }))
    );

    this.setMode(EditMode.None);
  }

  *getBusinessPartners() {
    if (!this.camp) {
      return;
    }
    try {
      this.businessPartnersLoading = true;
      this.businessPartners = yield this.businessPartnerRepository.getBusinessPartners({ campId: this.camp.id });
      this.businessPartners = this.businessPartners.map((bp) => ({
        ...bp,
        companyName: this.companies.find((company) => company.id === bp.companyId)?.name ?? '',
      }));
    } finally {
      this.counters.businessPartners = this.businessPartners;
      this.businessPartnersLoading = false;
    }
  }

  async getCompanies() {
    this.companies = await this.companyRepository.getCompanies();
  }

  async addHeadcountOfBusinessPartnersAsync(
    newRows: {
      company: ISelectItemDto;
      count: number;
    }[]
  ) {
    if (!this.camp) {
      return;
    }
    const data: ICreateOrUpdateBusinessPartnerDto = {
      campId: this.camp.id,
      data: newRows.map((row) => ({
        companyId: row.company.id,
        value: row.count,
      })),
    };
    await this.businessPartnerRepository.createOrUpdate(data);
  }
}
