import { useContext, PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Roles } from '../../../domain/enums/Roles';
import { StoreContext } from '../../stores/RootStore';

interface Props extends RouteProps {
  roles: Roles[];
}

export default function PrivateRoute(props: PropsWithChildren<Props>) {
  const { userStore } = useContext(StoreContext);

  const { roles: allowedRoles, ...other } = props;

  const roles = userStore.currentUser?.roles.filter((role) => allowedRoles.includes(role));
  if (roles && roles.length === 0) {
    return <Redirect to="/error/forbidden" />;
  }

  return <Route {...other} />;
}
