import { makeAutoObservable } from 'mobx';

export type Locale = 'ru' | 'en' | 'kz';

export default class ConfigStore {
  locale: Locale = 'ru';

  constructor() {
    makeAutoObservable(this);
  }

  setLocale(locale: Locale) {
    this.locale = locale;
  }
}
