import { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { StoreContext } from '../../../stores/RootStore';
import { DataGrid, GridPageChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import { Box, Button, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddDialog from './components/AddDialog/AddDialog';
import EditDialog from './components/EditDialog/EditDialog';
import AddCampDialog, { IFormValues as IAddCampDialogFormValues } from './components/AddCampDialog/AddCampDialog';
import AddCompanyDialog, {
  IFormValues as IAddCompanyDialogFormValues,
} from './components/AddCompanyDialog/AddCompanyDialog';
import IUserCampEnrichedDto from '../../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/IUserCampEnrichedDto';
import IDataTableSortDto from '../../../../domain/domainsDTOs/Common/IDataTableSortDto';

export default observer(function ManageCampAdmins() {
  const { manageCampAdminPage, campStore, campAreaStore, companyStore } = useContext(StoreContext);
  const { formatMessage } = useIntl();

  useEffect(() => {
    manageCampAdminPage.getList();
    campStore.getCampsAsync();
    campAreaStore.getCampAreasAsync();
    companyStore.getCompaniesAsync();
  }, [manageCampAdminPage, campStore, campAreaStore, companyStore]);

  function handlePageChange(params: GridPageChangeParams) {
    manageCampAdminPage.changePage(params.page + 1);
    manageCampAdminPage.getList();
  }

  function handlePageSizeChange(params: GridPageChangeParams) {
    manageCampAdminPage.changePageSize(params.pageSize);
    manageCampAdminPage.getList();
  }

  function handleSortModelChange(params: GridSortModelParams) {
    manageCampAdminPage.changeSort(params.sortModel as IDataTableSortDto<IUserCampEnrichedDto>[]);
    manageCampAdminPage.getList();
  }

  function handleClose() {
    manageCampAdminPage.toggleAddDialog();
  }

  function handleCloseCamp() {
    manageCampAdminPage.toggleAddCampDialog();
  }

  function handleCloseCompany() {
    manageCampAdminPage.toggleAddCompanyDialog();
  }

  async function handleSaveCamp(formValues: IAddCampDialogFormValues) {
    await manageCampAdminPage.createCamp(formValues);
    campStore.getCampsAsync();
  }

  async function handleSaveCompany(formValues: IAddCompanyDialogFormValues) {
    await manageCampAdminPage.createCompany(formValues);
    companyStore.getCompaniesAsync();
  }

  return (
    <div>
      <Box mb={1}>
        <Button startIcon={<AddIcon />} onClick={manageCampAdminPage.toggleAddDialog}>
          {formatMessage({ id: 'ManageCampAdmins.Add' })}
        </Button>
        <Button startIcon={<AddIcon />} onClick={manageCampAdminPage.toggleAddCampDialog}>
          {formatMessage({ id: 'ManageCampAdmins.AddCamp' })}
        </Button>
        <Button startIcon={<AddIcon />} onClick={manageCampAdminPage.toggleAddCompanyDialog}>
          {formatMessage({ id: 'ManageCampAdmins.AddCompany' })}
        </Button>
      </Box>
      <AddDialog
        isOpen={manageCampAdminPage.isAddDialogOpen}
        onClose={handleClose}
        onSave={(formValues) => manageCampAdminPage.create(formValues)}
      />
      <AddCampDialog
        isOpen={manageCampAdminPage.isAddCampDialogOpen}
        onClose={handleCloseCamp}
        onSave={handleSaveCamp}
      />
      <AddCompanyDialog
        isOpen={manageCampAdminPage.isAddCompanyDialogOpen}
        onClose={handleCloseCompany}
        onSave={handleSaveCompany}
      />
      {manageCampAdminPage.camps ? (
        <Paper>
          <DataGrid
            autoHeight
            disableColumnMenu
            disableColumnFilter
            disableColumnReorder
            disableColumnResize
            loading={manageCampAdminPage.loading}
            pageSize={manageCampAdminPage.pageSize}
            rowsPerPageOptions={[5, 10, 15, 50, 100]}
            rowCount={manageCampAdminPage.camps.recordsTotal}
            sortingMode="server"
            sortModel={manageCampAdminPage.camps.sort}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortModelChange={handleSortModelChange}
            columns={[
              {
                field: 'campArea',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.CampArea' }),
                sortable: false,
                flex: 1,
              },
              {
                field: 'campName',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.CampName' }),
                sortable: true,
                flex: 1,
              },
              {
                field: 'affiliated',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.Affiliated' }),
                sortable: false,
                flex: 1,
              },
              {
                field: 'status',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.Status' }),
                sortable: false,
                flex: 1,
              },
              {
                field: 'fullName',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.FullName' }),
                sortable: false,
                flex: 1,
              },
              {
                field: 'phoneNumber',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.PhoneNumber' }),
                sortable: false,
                flex: 1,
              },
              {
                field: 'email',
                headerName: formatMessage({ id: 'ManageCampAdmins.Table.Email' }),
                sortable: false,
                flex: 1,
              },
              {
                field: '',
                headerName: '',
                sortable: false,
                renderCell: EditDialog,
              },
            ]}
            rows={manageCampAdminPage.camps.data}
          />
        </Paper>
      ) : null}
    </div>
  );
});
