import { useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StoreContext } from '../../../../../stores/RootStore';
import { EditMode } from '../../../../../stores/EditMode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      ...theme.typography.button,
      padding: theme.spacing(1),
    },
  })
);

export default observer(function Totals() {
  const { homePage, campStore } = useContext(StoreContext);
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="flex-start" spacing={3}>
      <Grid item>
        <Autocomplete
          options={campStore.camps}
          getOptionLabel={(option) => option.name}
          value={homePage.camp}
          onChange={(e, v) => homePage.setCamp(v)}
          style={{ width: 300 }}
          disabled={homePage.editMode !== EditMode.None}
          closeIcon={null}
          renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
        />
      </Grid>
      <Grid item className={classes.text}>
        <FormattedMessage id="Home.Total.Rooms" />: {homePage.counters.roomsTotal ?? '-'}
      </Grid>
      <Grid item className={classes.text}>
        <FormattedMessage id="Home.Total.Bunks" />: {homePage.counters.bunksTotal ?? '-'}
      </Grid>
      <Grid item className={classes.text}>
        <FormattedMessage id="Home.Total.PeopleInCamp" />: {homePage.counters.peopleInCampTotal ?? '-'}
      </Grid>
    </Grid>
  );
});
