import * as yup from 'yup';

const MinNumber = 0;
const MaxNumber = 100000;

export const validationSchema = yup.object().shape({
  rows: yup.array().of(
    yup.object().shape({
      company: yup.object().required().nullable(false),
      count: yup.number().required().min(MinNumber).max(MaxNumber),
    })
  ),
});
