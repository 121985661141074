import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Header from './Header';
import Drawer from './Drawer';

import './layout.scss';

export default function Layout(props: React.PropsWithChildren<{}>) {
  return (
    <div className="layout-root">
      <CssBaseline />
      <Header />
      <main className="layout-main">
        <Drawer />
        <Box m={2} flex={1}>
          {props.children}
        </Box>
      </main>
    </div>
  );
}
