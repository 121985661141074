import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICreateCompanyDto from '../../../domain/domainsDTOs/CampsDataManagement/Company/ICreateCompanyDto';
import ICompanyRepository from '../../../domain/repository/Common/ICompanyRepository';
import IHttpService from '../../../domain/services/IHttpService';

export default class CompanyRepository implements ICompanyRepository {
  constructor(private _httpService: IHttpService) {}

  getCompanies() {
    return this._httpService.get<ISelectItemDto[]>('/api/CDM/Company/GetList');
  }

  create(data: ICreateCompanyDto) {
    return this._httpService.post<number>('/api/CDM/Company/actions/create', data);
  }
}
