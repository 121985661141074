import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { object, number, string } from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import { StoreContext } from '../../../../../stores/RootStore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (v: IFormValues) => void;
}

interface IFormValues {
  campId: number;
  userEmail: string;
}

export default observer(function AddDialog(props: Props) {
  const { campStore } = useContext(StoreContext);
  const { formatMessage } = useIntl();

  const formik = useFormik<IFormValues>({
    initialValues: {
      campId: NaN,
      userEmail: '',
    },
    enableReinitialize: true,
    onSubmit: (formValues, helpers) => {
      props.onSave(formValues);
      helpers.resetForm();
    },
    validationSchema: object().shape({
      campId: number().required(),
      userEmail: string()
        .required()
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
    }),
  });

  function handleCancel() {
    props.onClose();
    formik.resetForm();
  }

  return (
    <Dialog open={props.isOpen} onClose={handleCancel}>
      <DialogTitle>{formatMessage({ id: 'ManageCampAdmins.Add' })}</DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <Autocomplete
            options={campStore.camps}
            getOptionLabel={(option) => option.name}
            value={campStore.camps.find((c) => c.id === formik.values.campId)}
            onChange={(e, v) => formik.setFieldValue('campId', v?.id)}
            closeIcon={null}
            style={{ width: 300 }}
            renderInput={(params: any) => (
              <TextField
                placeholder="Select Camp"
                error={!!formik.errors.campId}
                {...params}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Box>
        <TextField
          placeholder="Email"
          value={formik.values.userEmail}
          onChange={formik.handleChange('userEmail')}
          size="small"
          error={!!formik.errors.userEmail}
          variant="outlined"
          style={{ width: 300 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={formik.submitForm} disabled={!formik.isValid || !formik.dirty} color="primary">
          {formatMessage({ id: 'Misc.SaveChanges' })}
        </Button>
        <Button onClick={handleCancel} color="secondary">
          {formatMessage({ id: 'Misc.CancelChanges' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
})
