import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (v: IFormValues) => void;
}

export interface IFormValues {
  name: string;
}

export default observer(function AddCompanyDialog(props: Props) {
  const { formatMessage } = useIntl();

  const formik = useFormik<IFormValues>({
    initialValues: {
      name: '',
    },
    enableReinitialize: true,
    onSubmit: (formValues, helpers) => {
      props.onSave(formValues);
      helpers.resetForm();
    },
    validationSchema: object().shape({
      name: string().required(),
    }),
  });

  function handleCancel() {
    props.onClose();
    formik.resetForm();
  }

  return (
    <Dialog open={props.isOpen} onClose={handleCancel}>
      <DialogTitle>{formatMessage({ id: 'ManageCampAdmins.AddCompany' })}</DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <TextField
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange('name')}
            size="small"
            error={!!formik.errors.name}
            variant="outlined"
            style={{ width: 300 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={formik.submitForm} disabled={!formik.isValid || !formik.dirty} color="primary">
          {formatMessage({ id: 'Misc.SaveChanges' })}
        </Button>
        <Button onClick={handleCancel} color="secondary">
          {formatMessage({ id: 'Misc.CancelChanges' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
})
