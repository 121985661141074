import { useCallback, useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditableNumberCell from '../../../../table/EditableNumberCell';
import { dateFormatter } from '../../../../../utils/gridFormatters';
import { StoreContext } from '../../../../../stores/RootStore';
import { EditMode } from '../../../../../stores/EditMode';
import ActionButtons from '../ActionButtons/ActionButtons';
import { CampProfileTypes } from '../../../../../../domain/enums/CampProfileTypes';
import { useSnackbar } from 'notistack';
import ValidationError from '../../../../../../domain/errors/ValidationError';

interface IFormValues {
  servicePersonnel: number;
  nonBadgedHeadcount: number;
}

const MinNumber = 0;
const MaxNumber = 100000;

export default observer(function CategoryGroupingTable() {
  const { formatMessage } = useIntl();
  const { homePage, campProfileStore } = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const { errors, values, isValid, isSubmitting, setFieldValue, submitForm, resetForm } = useFormik<IFormValues>({
    initialValues: {
      servicePersonnel: campProfileStore.campProfiles.get(CampProfileTypes.ServicePersonnel)?.value ?? 0,
      nonBadgedHeadcount: campProfileStore.campProfiles.get(CampProfileTypes.NonBadged)?.value ?? 0,
    },
    enableReinitialize: true,
    onSubmit: handleSave,
    validationSchema: yup.object().shape({
      servicePersonnel: yup.number().min(MinNumber).max(MaxNumber).required(),
      nonBadgedHeadcount: yup.number().min(MinNumber).max(MaxNumber).required(),
    }),
  });

  async function handleSave(formValues: IFormValues) {
    try {
      await homePage.submit([
        {
          campProfileTypeId: CampProfileTypes.ServicePersonnel,
          value: formValues.servicePersonnel,
        },
        {
          campProfileTypeId: CampProfileTypes.NonBadged,
          value: formValues.nonBadgedHeadcount,
        },
      ]);
    } catch (e) {
      if (e instanceof ValidationError) {
        enqueueSnackbar('Validation Error', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return;
      }
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  }

  const editNumberCell = useCallback(
    (params: GridCellParams) => (
      <EditableNumberCell
        {...params}
        hasError={!!errors[params.row.field as keyof IFormValues]}
        value={values[params.row.field as keyof IFormValues]}
        disabled={isSubmitting}
        onChange={(field, value) => setFieldValue(field, value)}
      />
    ),
    [errors, isSubmitting, setFieldValue, values]
  );

  return (
    <Box mt={3}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={3}>
          <ActionButtons
            isValid={isValid}
            isSubmitting={isSubmitting}
            currentEditMode={homePage.editMode}
            editMode={EditMode.HeadcountPersonal}
            onSave={submitForm}
            onCancel={() => {
              resetForm();
              homePage.setMode(EditMode.None);
            }}
            onEdit={() => homePage.setMode(EditMode.HeadcountPersonal)}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="h6">
            <FormattedMessage id="Home.PersonalCategoryTable.Name" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>
            <FormattedMessage id="Misc.Total" />: {homePage.counters.peopleTotal ?? '-'}
          </Typography>
        </Grid>
      </Grid>
      <Paper>
        <DataGrid
          density="compact"
          hideFooter
          autoHeight
          disableColumnMenu
          disableColumnFilter
          disableColumnReorder
          disableColumnResize
          loading={isSubmitting || campProfileStore.loading}
          rows={[
            {
              id: 1,
              title: formatMessage({ id: 'Home.PersonalCategoryTable.ServicePeopleCount' }),
              count: campProfileStore.campProfiles.get(CampProfileTypes.ServicePersonnel)?.value,
              date: campProfileStore.campProfiles.get(CampProfileTypes.ServicePersonnel)?.date,
              field: 'servicePersonnel',
            },
            {
              id: 2,
              title: formatMessage({ id: 'Home.PersonalCategoryTable.NonBadgedPeopleCount' }),
              count: campProfileStore.campProfiles.get(CampProfileTypes.NonBadged)?.value,
              date: campProfileStore.campProfiles.get(CampProfileTypes.NonBadged)?.date,
              field: 'nonBadgedHeadcount',
            },
          ]}
          columns={[
            {
              field: 'title',
              headerName: formatMessage({ id: 'Home.PersonalCategoryTable.PersonalCategory' }),
              flex: 3,
              sortable: false,
            },
            {
              field: 'count',
              headerName: formatMessage({ id: 'Home.PersonalCategoryTable.PeopleCount' }),
              flex: 1,
              sortable: false,
              renderCell: homePage.editMode === EditMode.HeadcountPersonal ? editNumberCell : undefined,
            },
            {
              field: 'date',
              headerName: formatMessage({ id: 'Home.PersonalCategoryTable.LastDate' }),
              flex: 1,
              sortable: false,
              valueFormatter: dateFormatter,
            },
          ]}
        />
      </Paper>
    </Box>
  );
});
