interface NestedMessages {
  [path: string]: NestedMessages | string;
}

/**
 * Flattens messages object
 *
 * example:
 * {
 *   somthing: {
 *     nested: "value"
 *   }
 * }
 * becomes:
 * {
 *   "something.nested": "value"
 * }
 *
 * @param nestedMessages
 * @param prefix string
 * @returns Record<string, string>
 * */
export function flattenMessages(nestedMessages: NestedMessages, prefix = ''): Record<string, string> {
  return Object.keys(nestedMessages).reduce((messages: Record<string, string>, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}
