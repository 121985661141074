import { flow, makeAutoObservable } from 'mobx';
import IDataTableResultDto from '../../../domain/domainsDTOs/Common/IDataTableResultDto';
import ICreateUserCampDto from '../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/ICreateUserCampDto';
import IEditUserCampDto from '../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/IEditUserCampDto';
import IUserCampEnrichedDto from '../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/IUserCampEnrichedDto';
import ICreateCampDto from '../../../domain/domainsDTOs/CampsDataManagement/Camp/ICreateCampDto';
import ICreateCompanyDto from '../../../domain/domainsDTOs/CampsDataManagement/Company/ICreateCompanyDto';
import IUserCampRepository from '../../../domain/repository/SinglePointOfContact/IUserCampRepository';
import ICampRepository from '../../../domain/repository/Camp/ICampRepository';
import ICompanyRepository from '../../../domain/repository/Common/ICompanyRepository';
import IDataTableSortDto from '../../../domain/domainsDTOs/Common/IDataTableSortDto';

export default class ManageCampAdminsStore {
  camps?: IDataTableResultDto<IUserCampEnrichedDto>;
  sort: IDataTableSortDto<IUserCampEnrichedDto>[] = [{ field: 'campName', sort: 'asc' }];
  page: number = 1;
  pageSize: number = 5;
  loading = false;
  isAddDialogOpen = false;
  isAddCampDialogOpen = false;
  isAddCompanyDialogOpen = false;

  constructor(
    private userCampRepository: IUserCampRepository,
    private campRepository: ICampRepository,
    private companyRepository: ICompanyRepository
  ) {
    makeAutoObservable(this, {
      getList: flow,
      create: flow,
    });
    this.toggleAddDialog = this.toggleAddDialog.bind(this);
    this.toggleAddCampDialog = this.toggleAddCampDialog.bind(this);
    this.toggleAddCompanyDialog = this.toggleAddCompanyDialog.bind(this);
  }

  *getList() {
    try {
      this.loading = true;
      this.camps = yield this.userCampRepository.getEnrichedList({
        sort: this.sort,
        page: this.page,
        pageSize: this.pageSize,
      });
    } finally {
      this.loading = false;
    }
  }

  *create(data: ICreateUserCampDto) {
    yield this.userCampRepository.create(data);
    this.isAddDialogOpen = false;
    this.getList();
  }

  *edit(data: IEditUserCampDto) {
    yield this.userCampRepository.edit(data);
    this.getList();
  }

  *createCamp(data: ICreateCampDto) {
    yield this.campRepository.create(data);
    this.isAddCampDialogOpen = false;
  }

  *createCompany(data: ICreateCompanyDto) {
    yield this.companyRepository.create(data);
    this.isAddCompanyDialogOpen = false;
  }

  changePage(newPage: number) {
    this.page = newPage;
  }

  changePageSize(newPageSize: number) {
    this.pageSize = newPageSize;
  }

  changeSort(sort: IDataTableSortDto<IUserCampEnrichedDto>[]) {
    this.sort = sort;
  }

  toggleAddDialog() {
    this.isAddDialogOpen = !this.isAddDialogOpen;
  }

  toggleAddCampDialog() {
    this.isAddCampDialogOpen = !this.isAddCampDialogOpen;
  }

  toggleAddCompanyDialog() {
    this.isAddCompanyDialogOpen = !this.isAddCompanyDialogOpen;
  }
}
