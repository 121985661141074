import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { object, number, string } from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import { StoreContext } from '../../../../../stores/RootStore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (v: IFormValues) => void;
}

export interface IFormValues {
  campAreaId: number;
  name: string;
  description: string;
  companyId: number;
}

export default observer(function AddCampDialog(props: Props) {
  const { campAreaStore, companyStore } = useContext(StoreContext);
  const { formatMessage } = useIntl();

  const formik = useFormik<IFormValues>({
    initialValues: {
      campAreaId: NaN,
      name: '',
      description: '',
      companyId: NaN
    },
    enableReinitialize: true,
    onSubmit: (formValues, helpers) => {
      props.onSave(formValues);
      helpers.resetForm();
    },
    validationSchema: object().shape({
      campAreaId: number().required(),
      name: string().required(),
      companyId: number().required()
    }),
  });

  function handleCancel() {
    props.onClose();
    formik.resetForm();
  }

  return (
    <Dialog open={props.isOpen} onClose={handleCancel}>
      <DialogTitle>{formatMessage({ id: 'ManageCampAdmins.AddCamp' })}</DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <Autocomplete
            options={campAreaStore.campAreas}
            getOptionLabel={(option) => option.name}
            value={campAreaStore.campAreas.find((c) => c.id === formik.values.campAreaId)}
            onChange={(e, v) => formik.setFieldValue('campAreaId', v?.id)}
            closeIcon={null}
            style={{ width: 300 }}
            renderInput={(params: any) => (
              <TextField
                placeholder="Select Camp Area"
                error={!!formik.errors.campAreaId}
                {...params}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <TextField
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange('name')}
            size="small"
            error={!!formik.errors.name}
            variant="outlined"
            style={{ width: 300 }}
          />
        </Box>
        <Box mb={1}>
          <TextField
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange('description')}
            size="small"
            variant="outlined"
            style={{ width: 300 }}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            options={companyStore.companies}
            getOptionLabel={(option) => option.name}
            value={companyStore.companies.find((c) => c.id === formik.values.companyId)}
            onChange={(e, v) => formik.setFieldValue('companyId', v?.id)}
            closeIcon={null}
            style={{ width: 300 }}
            renderInput={(params: any) => (
              <TextField
                placeholder="Select Company"
                error={!!formik.errors.companyId}
                {...params}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={formik.submitForm} disabled={!formik.isValid || !formik.dirty} color="primary">
          {formatMessage({ id: 'Misc.SaveChanges' })}
        </Button>
        <Button onClick={handleCancel} color="secondary">
          {formatMessage({ id: 'Misc.CancelChanges' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
})
