import IUserCampRepository from '../../../domain/repository/SinglePointOfContact/IUserCampRepository';
import IHttpService from '../../../domain/services/IHttpService';
import IDataTableRequestDto from '../../../domain/domainsDTOs/Common/IDataTableRequestDto';
import IDataTableResultDto from '../../../domain/domainsDTOs/Common/IDataTableResultDto';
import IUserCampEnrichedDto from '../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/IUserCampEnrichedDto';
import ICreateUserCampDto from '../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/ICreateUserCampDto';
import IEditUserCampDto from '../../../domain/domainsDTOs/SinglePointOfContact/UserCamp/IEditUserCampDto';

export default class UserCampRepository implements IUserCampRepository {
  constructor(private _httpService: IHttpService) {}

  getEnrichedList(data: IDataTableRequestDto<IUserCampEnrichedDto>) {
    return this._httpService.post<IDataTableResultDto<IUserCampEnrichedDto>>(
      '/api/SPOC/UserCamp/GetEnrichedList',
      data
    );
  }

  create(data: ICreateUserCampDto) {
    return this._httpService.post<number>('/api/SPOC/UserCamp/actions/create', data);
  }

  edit(data: IEditUserCampDto) {
    return this._httpService.post<number>('/api/SPOC/UserCamp/actions/edit', data);
  }
}
