import { flow, makeAutoObservable } from 'mobx';
import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICompanyRepository from '../../../domain/repository/Common/ICompanyRepository';

export default class CompanyStore {
  companies: ISelectItemDto[] = [];
  constructor(private companyRepository: ICompanyRepository) {
    makeAutoObservable(this, {
      getCompaniesAsync: flow
    });
  }

  *getCompaniesAsync() {
    this.companies = yield this.companyRepository.getCompanies();
  }
}
