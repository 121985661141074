import { makeAutoObservable } from 'mobx';

export default class LayoutStore {
  isDrawerOpened = false;
  isLanguageMenuOpened = false;
  isProfileMenuOpened = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleDrawer = () => {
    this.isDrawerOpened = !this.isDrawerOpened;
  };

  toggleLanguageMenu = () => {
    this.isLanguageMenuOpened = !this.isLanguageMenuOpened;
  };

  toggleProfileMenu = () => {
    this.isProfileMenuOpened = !this.isProfileMenuOpened;
  };
}
