import { ru } from './ru';
import { en } from './en';
import { kz } from './kz';
import { flattenMessages } from './flattenMessages';

export const messages = {
  ru: flattenMessages(ru),
  en: flattenMessages(en),
  kz: flattenMessages(kz),
};
