import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICreateCampDto from '../../../domain/domainsDTOs/CampsDataManagement/Camp/ICreateCampDto';
import ICampRepository from '../../../domain/repository/Camp/ICampRepository';
import IHttpService from '../../../domain/services/IHttpService';

export default class CampRepository implements ICampRepository {
  constructor(private _httpService: IHttpService) {}

  getCamps() {
    return this._httpService.get<ISelectItemDto[]>('/api/CDM/Camp/GetList');
  }

  create(data: ICreateCampDto) {
    return this._httpService.post<number>('/api/CDM/Camp/actions/create', data);
  }
}
