import IHttpService from '../../domain/services/IHttpService';

export default class HttpService implements IHttpService {
  get(url: string) {
    return this.makeRequest(url, 'GET');
  }

  post(url: string, data: any) {
    return this.makeRequest(url, 'POST', data);
  }

  patch(url: string) {
    return this.makeRequest(url, 'PATCH');
  }

  delete(url: string) {
    return this.makeRequest(url, 'DELETE');
  }

  private async makeRequest(url: string, method: string, data?: any) {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await fetch(url, { headers, method, body: JSON.stringify(data), redirect: 'manual' });

    if (response.status === 302 && response.redirected) {
      throw Error('User unauthorized');
    }

    if (response.ok) {
      try {
        return await response.json();
      } catch {
        return;
      }
    } else {
      throw Error(response.status.toString());
    }
  }
}
