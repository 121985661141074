import { useContext } from 'react';
import { observer } from 'mobx-react';
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { StoreContext } from '../../../../../stores/RootStore';
import { EditMode } from '../../../../../stores/EditMode';
import ActionButtons from '../ActionButtons/ActionButtons';
import { CampProfileTypes } from '../../../../../../domain/enums/CampProfileTypes';
import { useSnackbar } from 'notistack';
import ICampProfileDto from '../../../../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICampProfileDto';
import { format } from 'date-fns';
import styles from './CampProfileTable.module.scss';
import { HomePageErrors } from '../../../../../stores/domain/CampProfileValidation';
import ValidationError from '../../../../../../domain/errors/ValidationError';

type FormKey = keyof typeof CampProfileTypes;
type IFormValues = { [key in FormKey]: ICampProfileDto };

const MinNumber = 0;
const MaxNumber = 100000;

const ShowingCampProfileTypes = [
  CampProfileTypes.SingleRoom,
  CampProfileTypes.DoubleRoom,
  CampProfileTypes.TripleRoom,
  CampProfileTypes.QuadRoom,
  CampProfileTypes.BathRoom,
  CampProfileTypes.IsolationRoom,
  CampProfileTypes.SanitaryEpidemiologicalStationRoom,
];

const validationSchema = yup.object().shape(
  Object.fromEntries(
    ShowingCampProfileTypes.map((type) => [
      CampProfileTypes[type],
      yup.object().shape({
        value: yup.number().required().min(MinNumber).max(MaxNumber),
        сomments: yup.string(),
      }),
    ])
  )
);

const CampProfileTableRow = observer(
  ({
    campProfiles,
    type,
    nameId,
    editMode,
    isSubmitting,
    values,
    errors,
    pageErrors,
    setFieldValue,
  }: {
    errors: FormikErrors<IFormValues>;
    pageErrors: HomePageErrors;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<IFormValues>>;
    isSubmitting: boolean;
    campProfiles: Map<CampProfileTypes, ICampProfileDto>;
    values: IFormValues;
    type: CampProfileTypes;
    nameId: string;
    editMode: EditMode;
  }) => {
    const item = campProfiles.get(type);
    const rowKey = CampProfileTypes[type] as FormKey;

    return (
      <TableRow>
        <TableCell>
          <Typography color={pageErrors[type] ? 'error' : undefined}>
            <FormattedMessage id={nameId} />
          </Typography>
        </TableCell>
        <TableCell>
          {editMode === EditMode.RoomCapacity ? (
            <Input
              fullWidth
              disabled={isSubmitting}
              value={values[rowKey]?.value}
              error={!!errors[rowKey]?.value}
              onChange={(event) => setFieldValue(`${rowKey}.value`, parseInt(event.target.value))}
            />
          ) : (
            item?.value
          )}
        </TableCell>
        <TableCell>
          {editMode === EditMode.RoomCapacity ? (
            <Input
              fullWidth
              multiline
              disabled={isSubmitting}
              value={values[rowKey]?.comments}
              error={!!errors[rowKey]?.comments}
              onChange={(event) => setFieldValue(`${rowKey}.comments`, event.target.value)}
            />
          ) : (
            <span className={styles.comment}>{item?.comments}</span>
          )}
        </TableCell>
        <TableCell>{item?.date ? format(new Date(item?.date), 'dd-MM-yyyy HH:mm:ss') : '-'}</TableCell>
      </TableRow>
    );
  }
);

export default observer(function CampProfileTable() {
  const { homePage, campProfileStore } = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const { errors, values, isSubmitting, setFieldValue, submitForm, resetForm } = useFormik<IFormValues>({
    initialValues: Object.fromEntries(
      ShowingCampProfileTypes.map((type) => [CampProfileTypes[type], campProfileStore.campProfiles.get(type)])
    ) as IFormValues,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      try {
        await homePage.submit(Object.values(formValues));
      } catch (e) {
        if (e instanceof ValidationError) {
          enqueueSnackbar('Validation Error', {
            variant: 'error',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          });
          return;
        }

        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    },
    validationSchema,
  });

  return (
    <Box mt={3}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={3}>
          <ActionButtons
            isValid={true}
            isSubmitting={isSubmitting}
            currentEditMode={homePage.editMode}
            editMode={EditMode.RoomCapacity}
            onSave={submitForm}
            onCancel={() => {
              resetForm();
              homePage.setMode(EditMode.None);
            }}
            onEdit={() => homePage.setMode(EditMode.RoomCapacity)}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="h6">
            <FormattedMessage id="Home.RoomCategoryTable.Name" />
          </Typography>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="30%">
                <FormattedMessage id="Home.RoomCategoryTable.RoomCategory" />
              </TableCell>
              <TableCell width="20%">
                <FormattedMessage id="Home.RoomCategoryTable.RoomCount" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Home.RoomCategoryTable.Comments" />
              </TableCell>
              <TableCell width="20%">
                <FormattedMessage id="Home.RoomCategoryTable.LastModifiedDate" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.SingleRoom}
              nameId="Home.RoomCategoryTable.OneBedroom"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.DoubleRoom}
              nameId="Home.RoomCategoryTable.TwoBedroom"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.TripleRoom}
              nameId="Home.RoomCategoryTable.ThreeBedroom"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.QuadRoom}
              nameId="Home.RoomCategoryTable.FourBedroom"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
            <TableRow className={styles.divider}>
              <TableCell colSpan={99}>&nbsp;</TableCell>
            </TableRow>
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.BathRoom}
              nameId="Home.RoomCategoryTable.RoomWithBathroom"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.IsolationRoom}
              nameId="Home.RoomCategoryTable.RoomForIsolation"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
            <CampProfileTableRow
              campProfiles={campProfileStore.campProfiles}
              type={CampProfileTypes.SanitaryEpidemiologicalStationRoom}
              nameId="Home.RoomCategoryTable.RoomApprovedBySES"
              isSubmitting={isSubmitting}
              editMode={homePage.editMode}
              values={values}
              errors={errors}
              pageErrors={homePage.validation.errors}
              setFieldValue={setFieldValue}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
