import { makeAutoObservable } from 'mobx';
import IBusinessPartnerDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/IBusinessPartnerDto';
import ICampProfileDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICampProfileDto';
import { CampProfileTypes } from '../../../domain/enums/CampProfileTypes';

export default class CampProfileCounters {
  constructor(
    public campProfiles: Map<CampProfileTypes, ICampProfileDto>,
    public businessPartners: IBusinessPartnerDto[]
  ) {
    makeAutoObservable(this);
  }

  get roomsTotal() {
    const campProfiles = this.campProfiles;
    if (!campProfiles) {
      return undefined;
    }

    const singleRoom = campProfiles.get(CampProfileTypes.SingleRoom)?.value ?? 0;
    const doubleRoom = campProfiles.get(CampProfileTypes.DoubleRoom)?.value ?? 0;
    const tripleRoom = campProfiles.get(CampProfileTypes.TripleRoom)?.value ?? 0;
    const quadRoom = campProfiles.get(CampProfileTypes.QuadRoom)?.value ?? 0;

    return singleRoom + doubleRoom + tripleRoom + quadRoom;
  }

  get bunksTotal() {
    const campProfiles = this.campProfiles;
    if (!campProfiles) {
      return undefined;
    }

    const singleRoom = campProfiles.get(CampProfileTypes.SingleRoom)?.value ?? 0;
    const doubleRoom = campProfiles.get(CampProfileTypes.DoubleRoom)?.value ?? 0;
    const tripleRoom = campProfiles.get(CampProfileTypes.TripleRoom)?.value ?? 0;
    const quadRoom = campProfiles.get(CampProfileTypes.QuadRoom)?.value ?? 0;

    return singleRoom + doubleRoom * 2 + tripleRoom * 3 + quadRoom * 4;
  }

  get peopleTotal() {
    const campProfiles = this.campProfiles;
    if (!campProfiles) {
      return undefined;
    }

    const servicePersonnel = campProfiles.get(CampProfileTypes.ServicePersonnel)?.value ?? 0;
    const nonBadged = campProfiles.get(CampProfileTypes.NonBadged)?.value ?? 0;

    return servicePersonnel + nonBadged;
  }

  get isolationTotal() {
    const campProfiles = this.campProfiles;
    if (!campProfiles) {
      return undefined;
    }

    const inIsolation = campProfiles.get(CampProfileTypes.InIsolation)?.value ?? 0;
    const inShelter = campProfiles.get(CampProfileTypes.ShelterInAccommodation)?.value ?? 0;

    return inIsolation + inShelter;
  }

  get businessPartnerTotal() {
    if (!this.businessPartners) {
      return undefined;
    }

    return this.businessPartners.reduce((acc, partner) => acc + partner.value, 0);
  }

  get peopleInCampTotal() {
    const campProfiles = this.campProfiles;
    if (!campProfiles) {
      return undefined;
    }

    const businessPartnerTotal = this.businessPartnerTotal ?? 0;

    return businessPartnerTotal;
  }
}
