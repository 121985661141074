import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import ConfigStore from './ConfigStore';
import HomePageStore from './pages/HomePageStore';
import LayoutStore from './LayoutStore';
import HttpService from '../../infrastructure/services/HttpService';
import BusinessPartnerRepository from '../../infrastructure/repository/SinglePointOfContact/BusinessPartnerRepository';
import CampProfileRepository from '../../infrastructure/repository/SinglePointOfContact/CampProfileRepository';
import CompanyRepository from '../../infrastructure/repository/Common/CompanyRepository';
import CampRepository from '../../infrastructure/repository/Camp/CampRepository';
import CampAreaRepository from '../../infrastructure/repository/Common/CampAreaRepository';
import UserStore from './UserStore';
import UserCampRepository from '../../infrastructure/repository/SinglePointOfContact/UserCampRepository';
import ManageCampAdminsStore from './pages/ManageCampAdminsStore';
import CampStore from './domain/CampStore';
import CampAreaStore from './domain/CampAreaStore';
import CampProfileStore from './domain/CampProfileStore';
import CompanyStore from './domain/CompanyStore';

const httpService = new HttpService();

const businessPartnerRepository = new BusinessPartnerRepository(httpService);
const campProfileRepository = new CampProfileRepository(httpService);
const companyRepository = new CompanyRepository(httpService);
const campRepository = new CampRepository(httpService);
const campAreaRepository = new CampAreaRepository(httpService);
const userCampRepository = new UserCampRepository(httpService);

export class RootStore {
  public config = new ConfigStore();
  public layout = new LayoutStore();

  public manageCampAdminPage = new ManageCampAdminsStore(userCampRepository, campRepository, companyRepository);

  public userStore = new UserStore(httpService);
  public campStore = new CampStore(campRepository);
  public campAreaStore = new CampAreaStore(campAreaRepository);
  public companyStore = new CompanyStore(companyRepository);
  public campProfileStore = new CampProfileStore(this, campProfileRepository);
  public homePage = new HomePageStore(this, businessPartnerRepository, campProfileRepository, companyRepository);

  constructor() {
    makeAutoObservable(this);
  }
}

export const StoreContext = createContext<RootStore>(new RootStore());
