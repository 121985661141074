import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';

export default function NoRowsOverlay() {
  const { formatMessage } = useIntl();
  return (
    <Grid container justify="center" alignItems="center">
      <Box m={3}>{formatMessage({ id: 'Misc.NoRows' })}</Box>
    </Grid>
  );
}
