import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider } from 'notistack';
import { messages } from '../i18n/messages';
import { StoreContext, RootStore } from '../stores/RootStore';
import Routes from './routes/Routes';

const store = new RootStore();

function App() {
  return (
    <StoreContext.Provider value={store}>
      <IntlProvider messages={messages[store.config.locale]} locale={store.config.locale} defaultLocale="ru">
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </SnackbarProvider>
      </IntlProvider>
    </StoreContext.Provider>
  );
}

export default observer(App);
