import { flow, makeAutoObservable } from 'mobx';
import ICampProfileDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICampProfileDto';
import ICampProfileRepository from '../../../domain/repository/SinglePointOfContact/ICampProfileRepository';
import { RootStore } from '../RootStore';
import { CampProfileTypes } from '../../../domain/enums/CampProfileTypes';
import ICreateOrUpdateCampProfileDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICreateOrUpdateCampProfileDto';

const campProfileTypes = [
  CampProfileTypes.ServicePersonnel,
  CampProfileTypes.NonBadged,
  CampProfileTypes.InIsolation,
  CampProfileTypes.ShelterInAccommodation,
  CampProfileTypes.TotalExcludeIsolationAndSiA,
  CampProfileTypes.SingleRoom,
  CampProfileTypes.DoubleRoom,
  CampProfileTypes.TripleRoom,
  CampProfileTypes.QuadRoom,
  CampProfileTypes.BathRoom,
  CampProfileTypes.IsolationRoom,
  CampProfileTypes.SanitaryEpidemiologicalStationRoom,
];

export default class CampProfileStore {
  campProfiles: Map<CampProfileTypes, ICampProfileDto> = new Map();
  repository: ICampProfileRepository;
  rootStore: RootStore;
  loading = false;
  constructor(rootStore: RootStore, repository: ICampProfileRepository) {
    makeAutoObservable(this, { rootStore: false, repository: false, getCampProfiles: flow, createOrUpdate: flow });
    this.rootStore = rootStore;
    this.repository = repository;
  }

  *getCampProfiles() {
    if (!this.rootStore.homePage.camp) {
      return;
    }
    try {
      this.loading = true;
      this.campProfiles.clear();
      const response: ICampProfileDto[] = yield this.repository.getCampProfiles({
        campId: this.rootStore.homePage.camp.id,
        campProfileTypes,
      });
      response.forEach((profile) => {
        this.campProfiles.set(profile.campProfileTypeId, profile);
      });
      campProfileTypes.forEach((type) => {
        if (!this.campProfiles.has(type)) {
          this.campProfiles.set(type, {
            campProfileTypeId: type,
            value: 0,
            date: undefined,
            comments: undefined,
          });
        }
      });
    } finally {
      this.loading = false;
    }
  }

  *createOrUpdate(data: ICreateOrUpdateCampProfileDto[]) {
    try {
      this.loading = true;
      yield this.repository.createOrUpdate({
        profiles: data,
      });
      yield this.getCampProfiles();
    } finally {
      this.loading = false;
    }
  }
}
