import { useRef, useContext } from 'react';
import { observer } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { ReactComponent as LogoImg } from '../../assets/logo.svg';
import { StoreContext } from '../../stores/RootStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      zIndex: 1201,
      position: 'relative',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export default observer(function Header() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const profileIconEl = useRef(null);
  const { layout, userStore } = useContext(StoreContext);

  function handleLogout() {
    userStore.logout();
    layout.toggleProfileMenu();
  }

  return (
    <header className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => layout.toggleDrawer()}
          >
            <MenuIcon />
          </IconButton>
          <Box m={1}>
            <LogoImg />
          </Box>
          <Typography className={classes.title} variant="h6" noWrap>
            {formatMessage({ id: 'App.Name' })}
          </Typography>
          <div className={classes.grow} />
          <span>Support: <a href="mailto:tcoccrp@tengizchevroil.com" style={{ color: "white" }}>tcoccrp@tengizchevroil.com</a></span>
          <div className={classes.grow} />
          <Box ml={2}>
            {userStore.currentUser ? (
              <div ref={profileIconEl}>
                <Button endIcon={<MoreIcon />} color="inherit" onClick={() => layout.toggleProfileMenu()}>
                  {userStore.currentUser.name}
                </Button>
              </div>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        id="profile-menu"
        keepMounted
        anchorEl={profileIconEl.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={layout.isProfileMenuOpened}
        onClose={() => layout.toggleProfileMenu()}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </header>
  );
});
