import IBusinessPartnerRepository from '../../../domain/repository/SinglePointOfContact/IBusinessPartnerRepository';
import ICreateOrUpdateBusinessPartnerDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/ICreateOrUpdateBusinessPartnerDto';
import IGetBusinessPartnersDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/IGetBusinessPartnersDto';
import IBusinessPartnerDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/IBusinessPartnerDto';
import IHttpService from '../../../domain/services/IHttpService';

export default class BusinessPartnerRepository implements IBusinessPartnerRepository {
  constructor(private _httpService: IHttpService) {}

  getBusinessPartners(data: IGetBusinessPartnersDto): Promise<IBusinessPartnerDto[]> {
    return this._httpService.post('/api/SPOC/BusinessPartner/GetBusinessPartners', data);
  }

  createOrUpdate(data: ICreateOrUpdateBusinessPartnerDto): Promise<number> {
    return this._httpService.post('/api/SPOC/BusinessPartner/CreateOrUpdate', data);
  }
}
