export const ru = {
  App: {
    Name: 'Tengiz Camp Management',
  },
  AppBar: {
    Search: 'Поиск...',
  },
  Drawer: {
    MainMenu: {
      Home: 'Главная',
      ManageCampAdmins: 'Manage Camp Admins',
    },
    BottomMenu: {
      FAQ: 'FAQ',
      About: 'О нас',
    },
  },
  Misc: {
    Total: 'Всего',
    ChangeData: 'Изменить данные',
    SaveChanges: 'Сохранить',
    CancelChanges: 'Отменить',
    NoRows: 'Нет данных',
  },
  Home: {
    Total: {
      Rooms: 'Комнат всего',
      Bunks: 'Коек всего',
      PeopleInCamp: 'Кол-во людей в кэмпе всего',
    },
    PersonalCategoryTable: {
      Name: 'Раздел A. Сервисный персонал',
      PersonalCategory: 'Категория персонала',
      PeopleCount: 'Кол-во людей',
      LastDate: 'Последняя дата',
      ServicePeopleCount: '1. Кол-во сервисного персонала',
      NonBadgedPeopleCount: '2. Кол-во персонала без бейджей',
      TotalPeopleLivingInRoomsCount:
        'Общее кол-во людей, проживающих в жилых помещениях (исключая изоляцию и изолированных в жилых помещениях)',
    },
    IsolationTable: {
      Name: 'Раздел B. Изоляция и Карантин',
      LastDate: 'Последняя дата',
      PersonalCategory: 'Категория персонала',
      PeopleCount: 'Кол-во людей',
      IsolatedPeopleCount: '1. Кол-во людей на изоляции',
      IsolatedInShelter: '2. Кол-во людей, на карантин изолированных в жилых помещениях (Shelter In Accommodation)',
    },
    BusinessPartnerTable: {
      Name: 'Раздел C. Бизнес Партнеры',
      BusinessPartnerName: 'Название БП',
      PeopleCount: 'Кол-во жильцов/гостей',
      LastModifiedDate: 'Последняя дата изменений',
    },
    RoomCategoryTable: {
      Name: 'Раздел D. Профиль кэмпа',
      RoomCategory: 'Категория комнат',
      RoomCount: 'Кол-во комнат',
      Comments: 'Комментарии',
      LastModifiedDate: 'Последняя дата изменений',
      OneBedroom: '1. Одноместная',
      TwoBedroom: '2. Двухместная',
      ThreeBedroom: '3. Трехместная',
      FourBedroom: '4. Четырехместная',
      RoomWithBathroom: '5. Комната с ванной',
      RoomForIsolation: '6. Комната для изоляции',
      RoomApprovedBySES: '7. Комната, одобренная СЭС',
    },
  },
  ManageCampAdmins: {
    Add: 'Add camp admin',
    AddCamp: 'Add camp',
    AddCompany: 'Add company',
    Table: {
      CampArea: 'Camp Area',
      CampName: 'Camp',
      Affiliated: 'Affiliated',
      Status: 'Status',
      FullName: 'Full name',
      PhoneNumber: 'Phone number',
      Email: 'Email',
    },
    Form: {
      IsActive: 'Active?',
    },
  },
};
