import { makeAutoObservable } from 'mobx';
import IBusinessPartnerDto from '../../../domain/domainsDTOs/SinglePointOfContact/BusinessPartner/IBusinessPartnerDto';
import ICampProfileDto from '../../../domain/domainsDTOs/SinglePointOfContact/CampProfile/ICampProfileDto';
import { CampProfileTypes } from '../../../domain/enums/CampProfileTypes';
import CampProfileCounters from './CampProfileCounters';

export type HomePageErrors = { [key in keyof typeof CampProfileTypes]?: boolean };

export default class CampProfileValidation {
  errors: HomePageErrors = {};
  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.errors = {};
  }

  check(items: Map<CampProfileTypes, ICampProfileDto>, businessPartners: IBusinessPartnerDto[]): boolean {
    this.reset();

    const counters = new CampProfileCounters(items, businessPartners);

    const isolationRooms = items.get(CampProfileTypes.IsolationRoom)?.value ?? 0;
    const bathRooms = items.get(CampProfileTypes.BathRoom)?.value ?? 0;
    const sanitaryRooms = items.get(CampProfileTypes.SanitaryEpidemiologicalStationRoom)?.value ?? 0;

    if (!((counters.isolationTotal ?? 0) <= isolationRooms)) {
      this.errors[CampProfileTypes.IsolationRoom] = true;
    }

    if (!(bathRooms <= (counters.roomsTotal ?? 0))) {
      this.errors[CampProfileTypes.BathRoom] = true;
    }

    if (!(isolationRooms <= (counters.roomsTotal ?? 0))) {
      this.errors[CampProfileTypes.IsolationRoom] = true;
    }

    if (!(sanitaryRooms <= (counters.roomsTotal ?? 0) && sanitaryRooms >= isolationRooms)) {
      this.errors[CampProfileTypes.SanitaryEpidemiologicalStationRoom] = true;
    }

    return Object.keys(this.errors).length === 0;
  }
}
