import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import MUIDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { StoreContext } from '../../stores/RootStore';
import Protected from '../../components/auth/Protected';
import { Roles } from '../../../domain/enums/Roles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flex: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      zIndex: 1199,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        // width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    menuItemIcon: {
      minWidth: 'unset',
      marginRight: 16,
    },
  })
);

export default observer(function Drawer() {
  const classes = useStyles();
  const theme = useTheme();
  const { layout } = useContext(StoreContext);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <MUIDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: layout.isDrawerOpened,
        [classes.drawerClose]: !layout.isDrawerOpened,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: layout.isDrawerOpened,
          [classes.drawerClose]: !layout.isDrawerOpened,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={() => layout.toggleDrawer()}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button selected={location.pathname === '/'} onClick={() => history.push('/')}>
          <ListItemIcon className={classes.menuItemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Drawer.MainMenu.Home' })} />
        </ListItem>
        <Protected allowedRoles={[Roles.SPoCCampAdmin]}>
          <ListItem
            button
            selected={location.pathname === '/manage-camp-admins'}
            onClick={() => history.push('/manage-camp-admins')}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Drawer.MainMenu.ManageCampAdmins' })} />
          </ListItem>
        </Protected>
      </List>
    </MUIDrawer>
  );
});
