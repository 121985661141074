import { makeAutoObservable, flow } from 'mobx';
import IUserInfoDto from '../../domain/domainsDTOs/User/IUserInfoDto';
import IHttpService from '../../domain/services/IHttpService';

export default class UserStore {
  currentUser?: IUserInfoDto;
  loading = false;

  constructor(private _httpService: IHttpService) {
    makeAutoObservable(this, {
      getUser: flow,
    });
  }

  *getUser() {
    try {
      this.loading = true;
      this.currentUser = yield this._httpService.get('/api/Identity/User/GetCurrentUserInfo');
    } catch (e) {
      window.location.href = '/Account/Login?RedirectUrl=' + window.location.pathname;
    } finally {
      this.loading = false;
    }
  }

  logout() {
    this.currentUser = undefined;
    window.location.href = '/Account/Logout';
  }
}
