import { ChangeEvent, useCallback } from 'react';
import Input from '@material-ui/core/Input';
import { GridCellParams } from '@material-ui/data-grid';

interface Props {
  disabled?: boolean;
  hasError?: boolean;
  onChange: (field: 'string', value: number) => void;
}

export default function EditableNumberCell({ disabled, hasError, value, row, onChange }: Props & GridCellParams) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (Number.isNaN(value)) {
        return;
      }
      onChange(row.field, value ? parseInt(value) : 0);
    },
    [onChange, row.field]
  );

  return (
    <Input
      value={value}
      onKeyDown={(e) => e.stopPropagation()}
      disabled={disabled}
      onChange={handleChange}
      error={hasError}
    />
  );
}
