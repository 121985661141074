import { flow, makeAutoObservable } from 'mobx';
import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICampRepository from '../../../domain/repository/Camp/ICampRepository';

export default class CampStore {
  camps: ISelectItemDto[] = [];
  constructor(private campsRepository: ICampRepository) {
    makeAutoObservable(this, {
      getCampsAsync: flow
    });
  }

  *getCampsAsync() {
    this.camps = yield this.campsRepository.getCamps();
  }
}
