import { useContext, PropsWithChildren } from 'react';
import { Roles } from '../../../domain/enums/Roles';
import { StoreContext } from '../../stores/RootStore';

interface Props {
  allowedRoles: Roles[];
}

export default function Protected(props: PropsWithChildren<Props>) {
  const { userStore } = useContext(StoreContext);

  const roles = userStore.currentUser?.roles.filter((role) => props.allowedRoles.includes(role));
  if (!roles || roles.length === 0) {
    return null;
  }

  return <>{props.children}</>;
}
