import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import { EditMode } from '../../../../../stores/EditMode';

interface Props {
  isValid: boolean;
  isSubmitting: boolean;
  editMode: EditMode;
  currentEditMode: EditMode;
  onSave: () => void;
  onCancel: () => void;
  onEdit: () => void;
}

export default function ActionButtons(props: Props) {
  const { formatMessage } = useIntl();

  return (
    <Box justifyContent="end">
      {props.currentEditMode === props.editMode ? (
        <>
          <Button
            disabled={props.isSubmitting || !props.isValid}
            variant="text"
            disableElevation
            endIcon={<EditIcon />}
            onClick={props.onSave}
          >
            {formatMessage({ id: 'Misc.SaveChanges' })}
          </Button>
          <Button
            disabled={props.isSubmitting}
            variant="text"
            disableElevation
            endIcon={<BlockIcon />}
            onClick={props.onCancel}
          >
            {formatMessage({ id: 'Misc.CancelChanges' })}
          </Button>
        </>
      ) : (
        <Button
          variant="text"
          disableElevation
          endIcon={<EditIcon />}
          disabled={props.currentEditMode !== EditMode.None}
          onClick={props.onEdit}
        >
          {formatMessage({ id: 'Misc.ChangeData' })}
        </Button>
      )}
    </Box>
  );
}
