import ISelectItemDto from '../../../domain/domainsDTOs/Common/ISelectItemDto';
import ICampAreaRepository from '../../../domain/repository/Common/ICampAreaRepository';
import IHttpService from '../../../domain/services/IHttpService';

export default class CampAreaRepository implements ICampAreaRepository {
  constructor(private _httpService: IHttpService) {}

  getCampAreas() {
    return this._httpService.get<ISelectItemDto[]>('/api/CDM/CampArea/GetList');
  }
}
