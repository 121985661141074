import { CssBaseline } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import './layout.scss';

export default function ErrorLayout(props: PropsWithChildren<{}>) {
  return (
    <div className="error-page-container">
      <CssBaseline />
      <main>
        <WarningRoundedIcon style={{ fontSize: 80 }} color="action" />
        <div>{props.children}</div>
      </main>
    </div>
  );
}
